<template>
  <div class="tooltip-content">
    <TippyContent to="tooltip-active-inactive">
      <div class="pt-2 pb-3 px-1 text-left">
        <div class="text-base font-semibold dark:text-gray-600">
          Info Status
        </div>
        <div class="grid grid-cols-2 gap-5 items-center mt-2">
          <!-- info status active -->
          <div class="flex items-center col-span-1">
            <div class="w-7 h-7 bg-green-500 rounded-full"></div>
            <span class="ml-2 font-medium text-sm">Active</span>
          </div>

          <!-- info status inactive -->
          <div class="flex items-center col-span-1">
            <div class="w-7 h-7 bg-red-500 rounded-full"></div>
            <span class="ml-2 font-medium text-sm">Inactive</span>
          </div>
        </div>
      </div>
    </TippyContent>
  </div>
</template>
